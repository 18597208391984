import { get, put } from "@/api/config";

// == 审核上架信息 ==
export const AuditCollectionQuery = (params) =>
  get("v1/review/collection/list", params);

export const AuditCollectionUpdate = (id, params) =>
  put(`v1/review/collection/${id}`, params);

export const AuditNftQuery = (params) => get("v1/review/nft/list", params);

export const AuditNftUpdate = (id, params) =>
  put(`v1/review/nft/${id}`, params);

export const AuditMarketQuery = (params) =>
  get("v1/review/nftonline/list", params);

export const AuditMarketUpdate = (id, params) =>
  put(`v1/review/nftonline/${id}`, params);
